<template>
    <div>
        <!-- Form Modal -->
        <b-modal
            id="modal-triggers-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            size="lg"
            @ok="close()"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="!project.title">Sub Project Triggers</h5>
                <h5 v-if="project.title">{{ project.title }} Triggers</h5>
                
                <feather-icon
                    class="ml-4 pr-0 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="close()"
                />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <!-- <span>{{ project }}</span> -->
                <b-button
                    size="sm"
                    variant="primary"
                    @click="cancel()"
                >
                    <span>Done</span>
                </b-button>
                <b-button
                    size="sm"
                    @click="cancel()"
                    variant="outline-secondary"
                >
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <project-triggers-renderer :projects="ourProjects" :conditions="conditions" @checkboxToggled="checkboxToggled" />
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Projects</h5>
            </b-card-header>
            <b-card-body>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="submit">
                        <b-row>
                            <b-col
                                cols="12"
                                md="3"
                                class="mb-md-0 mb-2"
                            >
                                <b-form-group labe-for="title" label="Title">
                                    <validation-provider #default="{ errors }" name="tile">
                                        <b-form-input
                                            id="title"
                                            name="title"
                                            v-model="project.title"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            value=""
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.title">{{ serverErrors.title[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2"
                            >
                                <b-form-group labe-for="abbreviation" label="Abbreviation">
                                    <validation-provider #default="{ errors }" name="abbreviation">
                                        <b-form-input
                                            id="abbreviation"
                                            name="abbreviation"
                                            v-model="project.abbreviation"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            value=""
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.abbreviation">{{ serverErrors.abbreviation[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2"
                            >
                                <b-form-group labe-for="isMain" label="Type">
                                    <validation-provider #default="{ errors }" name="isMain">
                                        <b-form-select
                                            id="isMain"
                                            name="isMain"
                                            v-model="project.isMain"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            value=""
                                        >
                                            <b-form-select-option :value="null" selected>Select...</b-form-select-option>
                                            <b-form-select-option :value="1">Main Project</b-form-select-option>
                                            <b-form-select-option :value="0">Sub Project</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.isMain">{{ serverErrors.isMain[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2"
                                v-if="project.isMain === 0 || project.isMain === '0'"
                            >
                                <b-form-group labe-for="project_id" label="Under Project">
                                    <validation-provider #default="{ errors }" name="project_id">
                                        <b-form-select
                                            id="project_id"
                                            name="project_id"
                                            v-model="project.project_id"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        >
                                            <b-form-select-option :value="null" selected>Selection...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="item in allProjects(ourProjects)"
                                                :key="item.id"
                                                v-if="item.isProject === 1"
                                                :value="item.id"
                                            >
                                                {{ item.title }}
                                            </b-form-select-option> 
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.project_id">{{ serverErrors.project_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="1" class="pt-2">
                                <b-form-checkbox
                                    class="custom-control-info"
                                    v-model="project.isActive"
                                >
                                    Is Active
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" md="1" class="pt-2">
                                <b-button
                                    size="sm"
                                    variant="primary"
                                    @click="submit"
                                >
                                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                    <span v-if="!saving">Create</span>
                                    <span v-if="saving">Creating...</span>
                                </b-button>
                            </b-col>
                            <b-col cols="12" md="1" class="pt-2">
                                <b-button
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="resetForm"
                                >
                                    Reset
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row v-if="project.isMain === 0 || project.isMain === '0'">
                            <b-col cols="6" md="3" class="pt-0">
                                <b-row>
                                    <b-col cols="12" md="7">
                                        <b-form-checkbox
                                            class="custom-control-warning"
                                            v-model="project.isPopup"
                                        >
                                            Open as Popup
                                        </b-form-checkbox>
                                    </b-col>
                                    <b-col cols="12" md="5">
                                        <b-button
                                            size="sm"
                                            variant="info"
                                            @click="invokeTriggersForm"
                                        >
                                            <span>Triggers</span>
                                            <feather-icon
                                                icon="SunsetIcon"
                                                class="ml-50"
                                            />
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                            
                            <b-col cols="6" md="9" class="pt-0" v-if="(project.isMain === 0 || project.isMain === '0') && project.triggers.filter((e) => e.condition_id !== null && e.value !== null && !isNaN(e.value)).length > 0">
                                <b-row>
                                    <b-col cols="12" md="12">
                                        <b-form-checkbox
                                            class="custom-control-danger"
                                            v-model="project.recurring"
                                        >
                                            Recurring based on triggering question
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-card-body>
            <!-- <b-card-body>{{ project }}</b-card-body> -->
        </b-card>
        <!-- ./Filters -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search -->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveStatusVariant(data.item.status !== 1? 0 : (reachedEndDate(data.item.endDate)? 0 : data.item.status))}`"
                        class="text-capitalize"
                    >
                        {{ data.item.status !== 1? 'Closed' : (reachedEndDate(data.item.endDate)? 'Closed' : (data.item.status === 1? 'On Progress' : 'Closed')) }}
                    </b-badge>
                </template>

                <template #cell(type)="data">
                    <span
                        class="text-capitalize"
                    >
                        {{ data.item.isMain === 1? 'Main Project' : 'Sub Project' }}
                    </span>
                </template>

                <template #cell(under)="data">
                    <span
                        class="text-capitalize"
                    >
                        {{ data.item .abbreviation}}
                    </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>
        
                        <b-dropdown-item @click="invokeUpdateForm(data.item)" :title='data.item.title'>
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
    import { 
        BRow, BCol, BCard, BCardBody, BCardHeader,
        BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput, BFormDatepicker, BFormCheckbox, BButton,
        BTable, BPagination, BBadge, BDropdown, BDropdownItem, BSpinner,
    } from 'bootstrap-vue'
    // import ProjectsCollapsibleTree from '@core/components/cromis/ProjectsCollapsibleTree.vue'
    import ProjectTriggersRenderer from '@core/components/cromis/ProjectTriggersRenderer.vue'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    // import store from '@/store'
    import { ref, onMounted, onUnmounted } from '@vue/composition-api'
    import projectsStoreModule from '@/views/cromis/visitings/projects/projectsStoreModule'
    import useProjectsList from '@/views/cromis/visitings/projects/useProjectsList'
    import store from '@/store'

    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardBody, BCardHeader,
            BTable, BPagination, BBadge, BDropdown, BDropdownItem, BSpinner,
            BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput, BFormDatepicker, BFormCheckbox, BButton, vSelect,
            ValidationProvider, ValidationObserver,
            // ProjectsCollapsibleTree,
            ProjectTriggersRenderer,
        },
        directives: {},

        setup(props, context) {
            const saving = ref(false)
            const serverErrors = ref(null)
            const myModal = ref(null)

            const projects = ref([])
            const conditions = ref([])

            const project = ref({
                id: null,
                title: null,
                abbreviation: null,
                project_id: null,
                isMain: null,
                isPopup: false,
                recurring: false,
                isActive: true,
                triggers: [],
            })

            const CROMIS_STORE_MODULE_NAME = 'cromis-project'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, projectsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                conditions.value = await store.dispatch('cromis-project/conditions').then(response => {
                    return response.data.conditions
                })

                ourProjects.value = await store.dispatch('cromis-project/list', { sortBy: 'title', sortDesc: false, main: 1, isProject: 1 }).then(response => {
                    return response.data.projects
                })
            })

            const invokeTriggersForm= () => {

                myModal.value.show()
            }

            const findMainProject = (project) => {
                let innerProject = project
                for(let i = 0; i < ourProjects.value.length; i++){

                    if(project === null && project !== undefined){
                        if(ourProjects.value[i].id === project.project_id){
                            innerProject = projects.value[i]
                            findMainProject(innerProject)
                        }
                    }
                }

                return project
            }

            const allProjects = (projects) => {
                let mergedProjects = [];

                for(let i = 0; i < projects.length; i++){
                    mergedProjects.push(projects[i])
                    if(projects[i].projects.length > 0){
                        mergedProjects = mergedProjects.concat(allProjects(projects[i].projects))
                    }
                }

                return mergedProjects
            }

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
                ourProjects,

                // UI
                resolveStatusVariant,
            } = useProjectsList()

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()
                serverErrors.value = null

                // Handle form submit
                if (project.value.id === null || project.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(project.value)
            }

            const resetForm = () => {
                serverErrors.value = null
                project.value = {
                    id: null,
                    title: null,
                    abbreviation: null,
                    project_id: null,
                    isMain: null,
                    isPopup: false,
                    recurring: false,
                    isActive: true,
                    triggers: [],
                }
            }

            const mainProjects = () => {
                let mainProjects = []

                for(let i = 0; i < ourProjects.value.length; i++){
                    if(ourProjects.value[i].isMain === 1){
                        mainProjects.push(ourProjects.value[i])
                    }
                }

                return mainProjects
            }

            const extractQuestions = (mainProjects) => {
                let extractedQuestions = []

                for(let i = 0; i < mainProjects.length; i++){
                    for(let j = 0; j < mainProjects[i].questions.length; j++){
                        extractedQuestions.push(mainProjects[i].questions[j])
                    }

                    extractQuestions(mainProjects[i].projects)
                }

                return extractedQuestions
            }

            const invokeUpdateForm = async (item) => {
                await store.commit('cromisData/UPDATE_TRIGGERS', item.triggers)

                let itemChoices = [];
                for(let i = 0; i < item.triggers.length; i++){
                    itemChoices.push(item.triggers[i].question_id)
                }

                await store.commit('cromisData/UPDATE_CHOICES', itemChoices);

                project.value = {
                    id: item.id,
                    title: item.title,
                    abbreviation: item.abbreviation,
                    project_id: item.project_id,
                    isMain: item.project_id === null? null : item.isMain,
                    isPopup: !!item.isPopup,
                    recurring: !!item.recurring,
                    isActive: !!item.isActive,
                    triggers: item.triggers,
                }
            }

            const checkboxToggled = async (choices) => {
                project.value.triggers = await store.getters['cromisData/triggers']
            }

            const handleCreate = async () => {
                saving.value = true

                await store.dispatch('cromis-project/create', project.value)
                    .then(response => {
                        refetch()
                        saving.value = false
                        resetForm()

                        context.root.$swal({
                            icon: 'success',
                            title: `Project ${response.data.title}, has been registered successfully!`,
                            showConfirmButton: true,
                            timer: 5000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        // resetForm()
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true

                await store.dispatch('cromis-project/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to a project has been made successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                        resetForm()
                    })
                    .catch(error => {
                        saving.value = false
                        resetForm()
                        console.log(error)
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-project/remove', id)
                            .then(response => {
                                refetch()

                                context.root.$swal({
                                    icon: 'success',
                                    text: 'Project delete successfully!',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-outline-success',
                                    },
                                    buttonsStyling: false,
                                })
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: false,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            })
            }

            return {
                saving,
                serverErrors,
                myModal,
                invokeTriggersForm,
                project,
                ourProjects,
                conditions,
                allProjects,
                mainProjects,
                extractQuestions,
                checkboxToggled,

                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
                submit,
                resetForm,
                invokeUpdateForm,
                remove,
                findMainProject,

                // UI
                // resolveUserRoleVariant,
                // resolveUserRoleIcon,
                resolveStatusVariant,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
