<template>
    <div>
        <b-row>
            <b-col cols="12">
                <div
                    v-for="(level, i) in levels"
                    :key="i"
                    class="mb-1"
                >
                    <b-row>
                        <b-col
                            v-for="(e, x) in level.switches"
                        >
                            <span
                                :class="'text-light-' + e.color"
                            >
                                <b-card-text class="mb-0">
                                    <span v-if="e.project.abbreviation === null">{{ e.project.title }}</span>
                                    <span v-if="e.project.abbreviation !== null">{{ e.project.abbreviation }}</span>
                                </b-card-text>
                                <b-form-checkbox
                                    :checked="e.selected"
                                    :class="'custom-control-' + e.color"
                                    name="check-button"
                                    switch
                                    v-model="e.selected"
                                    @change="refreshLevels(i, e)"
                                >
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="BellOffIcon" />
                                    </span>
                                </b-form-checkbox>
                            </span>
                        </b-col>
                    </b-row>
                    <hr />
                </div>
            </b-col>
        </b-row>
        <span v-if="project === null">Questions <small>(Select project)</small></span>
        <span v-if="project !== null && project.under.abbreviation === null">Questions under {{ project.under.title }}</span>
        <span v-if="project !== null && project.under.abbreviation !== null">Questions under {{ project.under.abbreviation }}</span>
        <div v-if="project !== null">
            <!-- Table Container Card -->
            <b-card no-body class="mb-0" v-if="project.questions.length > 0">
                <div>
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="[project.questions.length]" :clearable="false"
                                class="per-page-selector d-inline-block mx-50 select-size-sm" />
                            <label>entries</label>
                        </b-col>
                        <!-- ./Per Page -->

                        <!-- Search -->
                        <b-col cols="12" md="6">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input
                                    class="d-inline-block mr-1"
                                    placeholder="Search..."
                                    size="sm"
                                    @keyup=""
                                />
                            </div>
                        </b-col>
                        <!-- ./Search -->
                    </b-row>
                    <!-- ./Table Top -->
                </div>

                <!-- Table -->
                <b-table
                    striped
                    hover
                    small
                    ref="records"
                    class="position-relative"
                    :items="project.questions"
                    responsive
                    :fields="['description', 'triggering_value']"
                    primary-key="id"
                    show-empty
                    empty-text="No records found"
                >
                    <template #cell(description)="data">
                        <b-form-checkbox
                            :value="data.item.id"
                            class="custom-control-success"
                            v-model="choices"
                            @change="checkboxToggled(data.item.id)"
                        >
                            {{ data.item.description }}
                        </b-form-checkbox>
                    </template>

                    <template #cell(triggering_value)="data">
                        <b-row>
                            <b-col
                                cols="4" v-if="choices.includes(data.item.id) && triggers.filter((e) => e.question_id === data.item.id).length > 0 && data.item.options.length > 0"
                                v-for="option in data.item.options"
                                :key="option.id"
                            >
                                <b-form-checkbox
                                    :value="option.id"
                                    :class="'custom-control-' + project.color"
                                    v-model="triggers.filter((e) => e.question_id === data.item.id)[0].options"
                                >
                                    {{ option.value }}
                                </b-form-checkbox>
                            </b-col>

                            <b-col cols="6"
                                v-if="choices.includes(data.item.id) && triggers.filter((e) => e.question_id === data.item.id).length > 0 && (data.item.type.tool === 'textbox' || data.item.type.tool === 'numberbox' || data.item.type.tool === 'calendar')"
                            >
                                <b-form-group
                                    :labe-for="data.item.id + '' + project.id"
                                    :label="'When' + conditions[6].tools"
                                >
                                    <b-form-select
                                        size="sm"
                                        :id="data.item.id + '' + project.id"
                                        v-model="triggers.filter((e) => e.question_id === data.item.id)[0].condition_id"
                                    >
                                        <b-form-select-option :value="null">Select...</b-form-select-option>
                                        <b-form-select-option
                                            v-for="condition in conditions.filter((e) => e.tools.includes(data.item.type.tool))"
                                            :key="condition.id"
                                            :value="condition.id"
                                        >
                                            {{ condition.description }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6"
                                v-if="choices.includes(data.item.id) && triggers.filter((e) => e.question_id === data.item.id).length > 0 && triggers.filter((e) => e.question_id === data.item.id)[0].condition_id !== null && (data.item.type.tool === 'textbox' || data.item.type.tool === 'numberbox')"
                            >
                                <b-form-group
                                    :labe-for="data.item.id + '' + project.id"
                                    label="Value"
                                >
                                    <b-form-input
                                        size="sm"
                                        :id="data.item.id + '' + project.id"
                                        :type="data.item.type.tool === 'numberbox' ? 'number' : 'text'"
                                        v-model="triggers.filter((e) => e.question_id === data.item.id)[0].value"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col cols="6"
                                v-if="choices.includes(data.item.id) && triggers.filter((e) => e.question_id === data.item.id).length > 0 && triggers.filter((e) => e.question_id === data.item.id)[0].condition_id !== null && data.item.type.tool === 'calendar'"
                            >
                                <b-form-group
                                    :labe-for="data.item.id + '' + project.id"
                                    label="Date"
                                >
                                    <b-form-datepicker
                                        :id="data.item.id + '' + project.id"
                                        size="sm"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        locale="en"
                                        v-model="triggers.filter((e) => e.question_id === data.item.id)[0].value"
                                    />
                                </b-form-group>
                            </b-col>

                        </b-row>
                    </template>
                </b-table>
                <!-- </b-table> -->

                <!-- Table Footer -->
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">Showing {{ 1 }} to {{ project.questions.length }} of {{ project.questions.length }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>

            </b-card>
            <!-- ./Table Container Card -->
        </div>
    </div>
</template>

<script>
    import { 
        BRow, BCol,
        BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInput, BFormDatepicker,
        BCard, BCardHeader, BCardBody, BCardText,
        BTable, BPagination, BBadge, BDropdown, BDropdownItem, BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { ref, onMounted, onUnmounted } from '@vue/composition-api'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import store from '@/store'
    export default {
        name: 'ProjectsCollapsibleTree',
        props: {
            projects: {
                type: Array,
                required: true
            },
            conditions: {
                type: Array,
                required: true
            }
        },
        components: {
            BRow, BCol, vSelect,
            BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInput, BFormDatepicker,
            BCard, BCardHeader, BCardBody, BCardText,
            BTable, BPagination, BBadge, BDropdown, BDropdownItem, BSpinner,
            AppCollapse,
            AppCollapseItem
        },

        setup(props, context) {
            const choices = ref([])
            const triggers = ref([])
            const switches = ref([])
            const levels = ref([])
            const project = ref(null)
            const perPage = ref(0)

            onMounted(async () => {
                choices.value = await store.getters['cromisData/choices']
                triggers.value = await store.getters['cromisData/triggers']
            })

            onUnmounted(async () => {
                await context.emit('checkboxToggled', null)
            })



            props.projects.map(e => {
                switches.value.push({project: e, questions: e.questions, color: e.color, selected: false })
            })

            levels.value.push({ switches: switches.value })

            const refreshLevels = (index, e) => {
                let newSwitches = []

                for(let x = levels.value.length - 1; x > index; x--){
                    levels.value.splice(x, 1)
                }

                if(e.selected){
                    project.value = {under: {id: e.project.id, title: e.project.title, abbreviation: e.project.abbreviation}, questions: e.project.questions, color: e.color}
                    perPage.value = project.value.questions.length

                    levels.value[index].switches.map((element) => {
                        if(element.project.id !== e.project.id){
                            element.selected = false
                        }
                    })

                    e.project.projects.map((element) => {
                        newSwitches.push({project: element, questions: element.questions, color: e.color, selected: false })
                    })

                    if(newSwitches.length > 0){
                        levels.value.push({ switches: newSwitches })
                    }
                }
                else{
                    project.value = null
                    perPage.value = 0
                }
            }

            const checkboxToggled = async (value) => {
                if(value !== 0){
                    if(!choices.value.includes(value)){
                        let storedChoices = await store.getters['cromisData/choices']

                        for(let i = 0; i < storedChoices.length; i++){
                            let found = false;

                            for(let x = 0; x < choices.value.length; x++){
                                if(choices.value[x] === storedChoices[i]){
                                    found = true
                                    break
                                }
                            }

                            if(!found){
                                for(let x = 0; x < triggers.value.length; x++){
                                    if(triggers.value[x].question_id === storedChoices[i]){
                                        await store.commit('cromisData/REMOVE_TRIGGER', triggers.value[x])
                                    }
                                }
                                await store.commit('cromisData/REMOVE_CHOICE', storedChoices[i])
                                break
                            }
                        }
                    }
                    else{
                        await store.commit('cromisData/ADD_CHOICE', value)

                        let localTrigger = {question_id: value, condition_id: null, value: null, options: []}

                        if(triggers.value.filter((e) => e.question_id === localTrigger.question_id).length === 0){
                            triggers.value.push(localTrigger)
                            await store.commit('cromisData/ADD_TRIGGER', localTrigger)
                        }
                    }
                }

                context.emit('checkboxToggled', null)
            }

            return {
                levels,
                switches,
                project,
                choices,
                triggers,
                checkboxToggled,
                refreshLevels,
                store,

                perPage,
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>